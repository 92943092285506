import React, {useState} from "react";
import { stepsStyleValidation } from "../bounty-functionality/stepsStyleValidation";
import removeGhosting from 'remove-drag-ghosting';

export default function GenerateSteps(props) {
  const {
    meshesForStep,
    setCurrentStep,
    loading,
    isTsPrepared,
    lowerSteps,
    upperSteps,
    allStepsForUpper,
    allStepsForLower,
    startTogether,
    passiveAligners,
    upperOverCorrectionStepsNumber,
    lowerOverCorrectionStepsNumber,
    isLoaded,
    isTSViewerFound,
    stepsRef,
    preveStep,
    setPreveStep,
    isAnimateStart,
    setRangeValue,
    steps,
    currentStep,
    setTooltipActive,
  } = props;
  let isPassiveAligners = passiveAligners
    ? "bounty_passiveAligners"
    : "bounty_emptyStep";
  let maxStepNumber = meshesForStep.length - 1;
let isUpper = true
  const addToRefs = (elem) => {
    if (isLoaded && isTSViewerFound) {
      if(elem && !stepsRef.current.includes(elem)) {
        stepsRef.current.push(elem)
      }
    }
  }
  function isMultipleof5 (index) {
    if (maxStepNumber >= 50 ) {
      if (index % 10 === 0) {
        return true
    } else {
        return false;
    }
    } else {
      if (index % 5 === 0) {
        return true
    } else {
        return false;
    }
    }
}
const drag = (event) => {
  removeGhosting(event);
}
window.addEventListener('dragstart', drag);
  return steps
    ? steps.map((step, stepNumber) => {
        return (
          <div draggable className={`bounty_step_div ${currentStep === stepNumber || (preveStep === stepNumber && !isAnimateStart) ? "bounty_active_step bounty_step_tooltip" : ""}`} key={`${stepNumber}`}
          style={loading || isTsPrepared ? {zIndex: 0} : {zIndex: 10} }
          onDragOver={() => {
            setPreveStep(stepNumber)
            setCurrentStep(stepNumber);
          }}
          onClick={() => {
            if (loading || isTsPrepared) return;
            setCurrentStep(stepNumber);
            setPreveStep(stepNumber)
            if(stepNumber === 0) {
              setRangeValue(0)
            }
          }}
          onMouseMove ={(e) => isAnimateStart ? null : (setTooltipActive(true), setPreveStep(stepNumber))} 
          onMouseOut={(e) => isAnimateStart ? null : (setTooltipActive(false), setPreveStep(currentStep))}
          >
              <div className={`${maxStepNumber <= 50 ? "bounty_small_steps" : maxStepNumber >50 && maxStepNumber < 100 ? "bounty_medium_steps" : "bounty_large_steps"} bounty_label_upper`}
                    style={ preveStep === stepNumber || currentStep === stepNumber ? {color: "#FFFFFF"} : isMultipleof5(stepNumber) ? {} : {visibility:'hidden', width:'0'}}>
                {stepNumber}
                </div>
          {stepNumber === 0 ? <div className="bounty_firstStep"/> : step.u === true ?      
          <div
            ref={addToRefs}
            className={stepsStyleValidation(
              stepNumber,
              lowerSteps,
              upperSteps,
              allStepsForUpper,
              allStepsForLower,
              startTogether,
              passiveAligners,
              upperOverCorrectionStepsNumber,
              lowerOverCorrectionStepsNumber,
              isUpper = true
            )}
          
          /> : <div className={`${isPassiveAligners} ${currentStep === stepNumber ? "bounty_active_step" : ""}`} />}
          {stepNumber === 0 ? <div className="bounty_firstStep"/>: step.l === true ? 
          <div
            ref={addToRefs}
            className={stepsStyleValidation(
              stepNumber,
              lowerSteps,
              upperSteps,
              allStepsForUpper,
              allStepsForLower,
              startTogether,
              passiveAligners,
              upperOverCorrectionStepsNumber,
              lowerOverCorrectionStepsNumber,
              isUpper = false
            )}
          /> : <div className={`${isPassiveAligners} ${currentStep === stepNumber ? "bounty_active_step" : ""}`}/>}
          </div>
        );
      })
    : null;
}
